/****************************************************/
/* supplementary styles for nicholasbattye.org
/****************************************************/

#vocatus {
	font-size: 1.7rem;
	font-style: italic;
	margin: 1rem 0 2rem 6rem;
}
#death-notice {
	border: 3px solid black;
	font-size: 1.45rem;
	margin: 1rem 5rem 3rem;
	max-width: 30rem;
	padding: 2rem;
}

.poem { /*in post*/
	margin: 2rem 1rem;
}

.cols-2-1 {
	display: grid;
	grid-template-columns: 2fr 1fr;
}
@media (max-width: 768px) { /*breakpoint*/
	.cols-2-1 {
	  grid-template-columns: none;
	} 
}
div.column {
	max-width: 150px;
}